@import "../imports";

#About {
  display: flex;
  position: relative;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: fit-content;
  width: 100%;
  user-select: none;
  border: 3px outset #00000025;
  font-size: clamp(14px, 1.1vmax, 22px);

  #about-header {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }

  #about-footer {

  }

  // p {
  // }

  p.hey {
    // border: 1px solid red;
    font-size: 115%;
  }

  .intro {
    // font-size: 85%;
    // border: 1px solid red;
  }

  #left-boundary {
    width: 20%;
    height: 100%;    
    // background: #00ffaa50;
    background: transparent;
    // border: 1px solid red;
    user-select: none;
    z-index: 1;
  }

  #center-holder {
    height: 100%;
    background: transparent;
    width: 60%;
    border: 4px ridge hsl(0deg, 0%, 35%);
    i {
      color: $p20;
    }
    overflow: visible;
  }

  #right-boundary {
    width: 20%;
    height: 100%;
    // background: #00ffaa50;
    // border: 1px solid red;
    user-select: none;
    background: transparent;
    // background: transparent;
    z-index: 1;
  }

  #iframe-container {
    display: flex;
    position: relative;
    // border: 2px solid red;
    justify-content: center;
    align-items: center;
    width: 100vw;
    background: transparent;
    height: 35vmin;

    will-change: filter;
    transition: filter 1000ms ease;
  }

  iframe {
    // opacity: 0.75;
    margin: auto;
    position: absolute;
    // border: 2px solid gold; 
    width: 100%;
    height: 100vw;
    background: transparent;
  }

  #cover {
    visibility: hidden;
    pointer-events: visiblePainted;
    user-select: all;
    display: flex;
    margin: auto;
    position: absolute;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    // border: 6px solid blue; 
    width: 100vw;
    height: 105vmin;
    overflow: visible;
    background: transparent;
  }

  #top-boundary {
    // background: white; 
    width: 100%;
    pointer-events: all;
    // user-select: none;
    justify-self: top;
    background: transparent;
    flex-grow: 1;
    z-index: 1;
    visibility: visible;
  }

  #knockout {
    height: 35vmin;
    width: 60%;
    // justify-self: center;
    // align-self: center;
    // border: 2px solid gold;
    user-select: none;
    pointer-events: none;
    // background: #3300ff25;
    // z-index: -4;
    background: transparent;
  }

  #bottom-boundary {
    width: 100%;    
    pointer-events: all;
    flex-grow: 1;
    background: transparent;
    z-index: 1;
    // background: white;
    visibility: visible;
  }

  #skyline{
    // suck it dark reader
    color-scheme: unset !important;
  }

  p {
    padding: 1vh 4vw;
    text-align: start;
    font-weight: 400;

    &:first-of-type {
      font-weight: 100;
    }
  }
}