@import "../imports";

/* should have just made a component for this but whatever */

// should shrink based on screen width...
// if display is landscape.. push to bottom?
// ez pz

footer {
  position: relative;
  height: 100px;
  width: 100%;
  padding: 0vh 1vh;
  overflow: hidden;


  span {
    position: absolute;
    width: fit-content;
    // height: 90%;
    display: inline-block;
    font-size: 80%;
    writing-mode: vertical-lr;
    text-orientation: upright;
    left: 0;
  }

  // box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.5);

  .links-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    /* max-width: 1000px; ??? */
  }

  #card {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    fill: $s60;
    height: 70px;
    width: 70px;
    filter: drop-shadow(0px 0px 2px black);
    border-radius: 50%;

    // - - - - - - -- - - - - - - - - - -

    will-change:
      fill,
      height,
      width,
      filter;
    transition:
    // fill glitches on <a> tags in safari... there is no transition
      fill 0ms ease-in-out,
      filter 100ms ease-in-out,
      height 300ms linear,
      width 300ms linear;
    // - - - - - - -- - - - - - - - - - -

    &:hover {
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      fill: $a65;

      -webkit-filter: drop-shadow(0 0 3px $s75);
      filter: drop-shadow(0px 0px 3px $s75);

      .email_1 {
        opacity: 0;
        transform: rotate(360deg);
      }

      .email_3 {
        opacity: 1;
        transform: translate(-6px, 4px) rotate(360deg);
      }
    }

    &:active {
      transform: scale(1.1);
      -webkit-transform: scale(1.1);
    }
  }

  .linkedin {
    position: absolute;
    height: 70%;
    width: 70%;
    will-change: fill, height, width;
    // z-index: -5;
    user-select: none;
  }

  /* https://stackoverflow.com/questions/38877174/svg-transition-on-hover-fails-in-safari-9-1-2-11601-7-7 */
  .github {
    position: absolute;
    height: 80%;
    width: 80%;
    will-change: fill, height, width;
  }

  .email_1 {
    position: absolute;
    height: 70%;
    width: 70%;
    opacity: 1;
    will-change: fill, opacity, transform, height, width;
    transition: opacity 600ms ease, transform 1000ms;
  }

  .email_3 {
    position: absolute;
    height: 70%;
    width: 70%;
    opacity: 0;
    will-change: fill, opacity, transform, height, width;
    transition: opacity 600ms ease, transform 1000ms;
  }

  .phone {
    position: absolute;
    height: 75%;
    width: 75%;
    will-change: fill, height, width;
  }
}

@media (orientation: portrait) {
  footer {
    display: absolute;
    bottom: 0;
  }
}

/* and (max-width: 1800px) ??? */
@media (orientation: landscape) and (max-height: 600px) {
  footer {
    display: relative;
  }

  footer #card {
    height: 12vmax;
    width: 12vmax;
  }
}