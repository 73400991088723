@import "../imports";

#Projects {
  position: relative;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: center;
  height: fit-content;
  width: 100%;
  border: 3px outset #00000025;
  user-select: none;

  .project-card-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
    width: 250px;
    overflow: visible;
    border-radius: 12.5px;
    margin: 2%;
    // border: 2px solid blue;
    // z-index: 1234;
    transition: all 1000ms ease;

    &:hover {
      // transform: scale(1.03);
    }
  }

  .project-card:hover {
    filter:
      drop-shadow(0 0 2.5px $s55) drop-shadow(0 0 7.5px $s05);

    .project-card-container {
      // transform: scale(1.03);
    }
  }

  .project-card {
    position: relative;
    display: flex;
    height: 400px;
    width: 250px;
    flex-direction: column;
    transition: all 1000ms ease;
    overflow: hidden;
    border-radius: 12.5px;
    filter:
      drop-shadow(0 0 1.5px $s55) drop-shadow(0 0 3.5px $s05);
    will-change: transform;
    color: $s80;

    .spinner {
      position: absolute;
      bottom: -30px;
      right: -30px;
      color: orange;
      background-color: $s55;
      height: 60px;
      width: 60px;
      border-radius: 50%;
      border: 2px solid $s55;
      pointer-events: all;
      will-change: transform, z-index;
      filter: drop-shadow(0 0 1.5px $s55);

      z-index: 2;

      svg {
        position: absolute;
        fill: $p30;
        top: 3px;
        left: 3px;
      }

      &:hover {
        svg {
          fill: $a50;
        }
      }

      &:active {
        // transform: scale(1.05)
      }
    }

    .front,
    .back {
      will-change: z-index;
      position: absolute;
      border: 5px solid $s55;
      // border-color: transparentize($color: $s55, $amount: 0.85);
      height: 100%;
      width: 100%;
      filter: drop-shadow(0 0 1px $s75);
      overflow: hidden;
      border-radius: 12.5px;
      color: $s85;

      .badge {
        height: fit-content;
        width: fit-content;
        padding: 1px 2px;
        margin: 5px 5px;
        font-weight: 300;
        background: transparentize($color: $s45, $amount: 0.85);
        border-radius: 6.25px;
        border: 1.5px solid transparentize($color: $s75, $amount: 0.5);
      }

      .content {
        background-image: linear-gradient($p15, $p25);
      }

    }

    .front {
      visibility: visible;
      z-index: 1;
      will-change: z-index;
      transition: z-index 1000ms ease;
      text-shadow: 0 0 3px $s35;
      text-shadow: 0 0 1px white;

      .project-title {
        display: flex;
        justify-content: center;
        font-size: 20px;
        padding: 1vh 0;
        color: $s65;
        text-align: center;
        filter: drop-shadow(0 0 2px $s05);
      }

      .project-subtitle {
        position: relative;
        display: flex;
        justify-content: center;
        background: $s55;
        // height: 23px;
        color: $p15;
        padding: 0.25vh;
        text-align: center;
        font-size: 15px;
        font-weight: 900;
        filter: drop-shadow(0 0 1px $s75);
      }

      .tool-badge-container {
        display: flex;
        flex-wrap: wrap;
        height: fit-content;
        justify-content: center;
        font-size: 13px;
        padding-top: 0px;
        font-weight: 500;
        word-spacing: -3px;
      }

      .role {
        display: flex;
        flex-wrap: wrap;
        word-wrap: break-word;
        border: 1px solid transparentize($color: $p65, $amount: 0.25);
        border-radius: 6.25px;
        padding: 4px 16px;
        margin: 10px 2px 7px 2px;
        text-align: center;
        font-size: 14px;
        line-height: 17px;
        word-spacing: -0.5px;
        font-weight: 300;
        background: transparentize($color: $p65, $amount: 0.85), ;
        align-items: center;
        justify-content: center;
      }

      .details {
        display: flex;
        flex-grow: 3;
        border-radius: 6.25px;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        padding: 6px 10px;
        background: linear-gradient(transparentize($color: $s65, $amount: 0.85),
            transparentize($color: black, $amount: 0.99));
        margin: 2px;
        font-size: 15px;
        line-height: 17px;
        word-spacing: 0px;
        font-weight: 300;
        overflow-y: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */

        &::-webkit-scrollbar { /* Chrome*/
          display: none;
        }

        .future{
          font-style: italic;
          margin-top: 1vmax;
          font-size: 80%;
        }
      }
    }

    .back {
      z-index: 0;
      will-change: z-index;
      transition: z-index 1000ms ease;
      transform: rotateY(0.5turn);
      -moz-transform: rotateY(0.5turn);
      -webkit-transform: rotateY(0.5turn);
      -o-transform: rotateY(0.5turn);
      -ms-transform: rotateY(0.5turn);

      .iframe-container {
        position: relative;
        width: 100%;
        height: 100%;
        user-select: none;
        pointer-events: none;
        overflow: hidden;
        background: none;

        iframe {
          z-index: 1;
          position: relative;
          height: 315px;
          background: none;
          width: 100%;
          border: double 3px $s45;
          // need to add mix blend mode : difference to negate night mode
          border-radius: 7.5px;
          overflow: hidden;
        }

        .iframe-veil {
          content: "";
          position: absolute;
          z-index: 5;
          height: 315px;
          width: 100%;
          // background: blue;
          mix-blend-mode: difference;
          border-radius: 7.5px;
          will-change: background-color;
          transition: background-color 900ms linear;
          // overflow: hidden;
          border: double 3px $s50;
        }

        .iframe-nav {
          display: flex;
          pointer-events: all;
          width: 90%;
          overflow: visible;
          margin: 0% auto;
          display: flex;

          a {
            flex-grow: 1;
            transition: color 300ms linear, filter 300ms linear, text-decoration 300ms ease;
            text-decoration: none;
            font-weight: 400;
            color: $s85;
            text-align: center;
            height: fit-content;
            padding: 1px 0px;
            margin: 6px 0px;
            border-radius: 6.25px;
            border: 1.5px outset transparentize($color: $s75, $amount: 0.2);
            background: transparentize($color: $s45, $amount: 0.85);
            font-size: clamp(15px, 80%, 18px);

            &:hover {
              color: $a65;
              filter: drop-shadow(0px 0px 0.5px $a75);
              transform: scale3d(1.01);
              text-decoration: underline;
            }

            &:active {
              border-style: inset;
              border-color: $a65;
              ;
            }
          }

          .deployment {
            border-radius: 6.25px 0px 0px 6.25px;
          }

          .center {
            width: 1px;
            height: 95px;
          }

          .repo {
            border-radius: 0px 6.25px 6.25px 0px;
          }
        }
      }
    }

    .content {
      position: absolute;
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      overflow: hidden;
      padding: 2.5%;
    }
  }

  .test2 {
    transition: all 1000ms ease;

    .spinner {
      z-index: 3;

      svg {
        z-index: 5;
      }
    }

    .front {
      z-index: 0;
    }

    .back {
      z-index: 1;
    }
  }
}