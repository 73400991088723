@font-face {
  font-family: "FiraCode";
  // src: url("/src/assets/fonts/FiraCode-VariableFont_wght.ttf");
  src: url("../src/assets/fonts/FiraCode-VariableFont_wght.ttf")
    format("truetype");
}
.firacode {
  font-family: "FiraCode";
}

@font-face {
  font-family: "Goldman-Regular";
  src: url("../src/assets/fonts/Goldman-Regular.ttf") format("truetype");
}
.goldman {
  font-family: "Goldman-Regular";
}

/*- - - - primary - - - -*/
$p05: hsl(0, 0%, 5%);
$p10: hsl(0, 0%, 10%);
$p15: hsl(0, 0%, 15%);
$p20: hsl(0, 0%, 20%);
$p25: hsl(0, 0%, 25%);
$p30: hsl(0, 0%, 30%);
$p35: hsl(0, 0%, 35%);
$p40: hsl(0, 0%, 40%);
$p45: hsl(0, 0%, 45%);
$p50: hsl(0, 0%, 50%); /*🔆*/
$p55: hsl(0, 0%, 55%);
$p60: hsl(0, 0%, 60%);
$p65: hsl(0, 0%, 65%);
$p70: hsl(0, 0%, 70%);
$p75: hsl(0, 0%, 75%);
$p80: hsl(0, 0%, 80%);
$p85: hsl(0, 0%, 85%);
$p90: hsl(0, 0%, 90%);
$p95: hsl(0, 0%, 95%);

/*- - - - secondary - - - -*/
$s05: hsl(30, 100%, 5%);
$s10: hsl(30, 100%, 10%);
$s15: hsl(30, 100%, 15%);
$s20: hsl(30, 100%, 20%);
$s25: hsl(30, 100%, 25%);
$s30: hsl(30, 100%, 30%);
$s35: hsl(30, 100%, 35%);
$s40: hsl(30, 100%, 40%);
$s45: hsl(30, 100%, 45%);
$s50: hsl(30, 100%, 50%); /*🔆*/
$s55: hsl(30, 100%, 55%);
$s60: hsl(30, 100%, 60%);
$s65: hsl(30, 100%, 65%);
$s70: hsl(30, 100%, 70%);
$s75: hsl(30, 100%, 75%);
$s80: hsl(30, 100%, 80%);
$s85: hsl(30, 100%, 85%);
$s90: hsl(30, 100%, 90%);
$s95: hsl(30, 100%, 95%);

// $s05: hsl(210, 100%, 05%);
// $s10: hsl(210, 100%, 10%);
// $s75: hsl(210, 100%, 15%);
// $s20: hsl(210, 100%, 20%);
// $s25: hsl(210, 100%, 25%);
// $s30: hsl(210, 100%, 30%);
// $s35: hsl(210, 100%, 35%);
// $s40: hsl(210, 100%, 40%);
// $s45: hsl(210, 100%, 45%);
// $s50: hsl(210, 100%, 50%); /*🔆*/
// $s55: hsl(210, 100%, 55%);
// $s60: hsl(210, 100%, 60%);
// $s65: hsl(210, 100%, 65%);
// $s70: hsl(210, 100%, 70%);
// $s75: hsl(210, 100%, 75%);
// $s80: hsl(210, 100%, 80%);
// $s85: hsl(210, 100%, 85%);
// $s90: hsl(210, 100%, 90%);
// $s95: hsl(210, 100%, 95%);

/*- - - - - accent - - - - -*/
$a05: hsl(75, 100%, 5%);
$a50: hsl(75, 100%, 10%);
$a75: hsl(75, 100%, 15%);
$a20: hsl(75, 100%, 20%);
$a25: hsl(75, 100%, 25%);
$a30: hsl(75, 100%, 30%);
$a35: hsl(75, 100%, 35%);
$a40: hsl(75, 100%, 40%);
$a45: hsl(75, 100%, 45%);
$a50: hsl(75, 100%, 50%);
$a55: hsl(75, 100%, 55%);
$a60: hsl(75, 100%, 60%); /*🔆*/
$a65: hsl(75, 100%, 65%);
$a70: hsl(75, 100%, 70%);
$a75: hsl(75, 100%, 75%);
$a80: hsl(75, 100%, 80%);
$a85: hsl(75, 100%, 85%);
$a90: hsl(75, 100%, 90%);
$a95: hsl(75, 100%, 95%);