@import "../imports";

#Resume {
  opacity: 0;
  width: 100%;
  height: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
  transition: opacity 2s ease, background-color 1s ease;
  font-weight: 100;
  border: 3px inset #00000025;
  overflow-x: hidden;
  overflow-y: visible;
  padding: 0% 5%;
  font-size: clamp(14px, 1.1vmax, 22px);


  hr {
    border: 0.5px solid $s25;
  }

  // - - - - - - - - - - - - - - -

  >div {
    display: flex;
    flex-direction: column;
    padding: 2vmin 0vmin;
    text-align: center;
    border-bottom: 1.5px solid rgba(0, 0, 0, 0.10);
  }

  .heading {
    color: $s65;
    font-weight: 500;

    span {
      display: inline-block;

      &::first-letter {
        font-size: 125%;
      }
    }
  }

  .intro {
    text-align: justify;
  }

  .hard-skills {
    text-align: center;
  }

  .soft-skills {
    text-align: center;
  }

  table {
    display: flex;
    width: 100%;
    margin: 1vh 0vh;
    display: table;

    th {
      display: flex;
      width: 100%;
      color: $s75;
    }

    tr {
      display: flex;
      width: 100%;
      flex-grow: 1;
      justify-content: space-between;

      :first-child:not(th) {
        text-align: left;
        justify-content: space-between;
        width: 100%;
        white-space: pre-wrap;
        margin-right: clamp(13px, 3vw, 15px);
      }

      :nth-of-type(2n):not(li):not(ul) {
        display: flex;
        justify-content: flex-end;
        white-space: nowrap;
        width: max-content;
        align-content: flex-end;
      }
    }

    a {
      text-decoration: none;
      color: inherit;
    }

    ul {
      width: 100%;

      li {
        width: 100%;
        margin: 1.1vmin 0;
      }

      ul {
        li {
          margin-left: 2vmin;
          color: $s90;
        }

        ul {
          li {
            list-style-type: "- ";
            margin-left: 5vmin;
            color: $s95;
          }
        }
      }
    }
  }

  .work-experience {
    tr:first-child {
      color: $s75;
    }

    tr:nth-last-of-type(2n) {
      // border: 1px solid red;
      font-style: italic;
      color: $s85;
    }
  }

  .education {
    .institution {
      font-weight: bold;
    }

    .location {
      font-weight: bold;
    }
  }
}