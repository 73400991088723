@import "./imports";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
}

html,
body {
  height: 100vh;
  width: 100vw;
}
body {
  font-weight: 600;
  line-height: 1.5;
  font-family: "Roboto", sans-serif;
}

#root,
#App {
  height: 100%;
  width: 100%;
}

#App {
  // position: relative;
  display: flex;
  flex-direction: column;
  color: $s90;
  background: $p25;
  letter-spacing: 1px;
  word-spacing: 2px;
}

#veil {
  will-change: background-color;
  transition: background-color 1000ms ease;
  position: fixed;
  height: 100%;
  width: 100%;
  mix-blend-mode: difference;
  user-select: none;
  pointer-events: none;
  z-index: 2;
}

#veil-toggle {
  position: fixed;
  bottom: 0;
  right: 45px;
  height: fit-content;
  width: fit-content;
  padding-left: 0.5vw;
  z-index: 3;
  
  img {
    position: fixed;
    will-change: bottom;
    transition: bottom 600ms linear;
    
    height: 60px;
    width: 60px;
    bottom: -30px;
    right: 5px;
  }
  
  &:hover {
    img {
      bottom: 0.5vh;
    }
  }
}
header,
footer {
  display: flex;
  
  height: 100px;
  align-items: center;
  justify-content: center;
  
  background: $p20;
  filter: drop-shadow(0 0 2px $s60);

  transition: all 300ms linear;

}
footer {

}

#banner {
  position: absolute;
  user-select: none;
  pointer-events: none;
  top: 0;
  left: 0;
  display: table;
  text-align: center;
  font-family: goldman-regular;
  width: max-content;
  color: transparentize($s65, 0.75);
  filter: drop-shadow(0 0 0.5px $s65);
  // text-shadow: 0 0 0.001px $s35;
  // border: 1px solid black;
  writing-mode: vertical-lr;
  text-orientation: upright;
  line-height: 125%;
  padding-right: 0.03rem;
  letter-spacing: 0px;
  font-weight: 900;
  font-size: 11.5px;
}

#Projects {
  background: $p30;
}

main {
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.1;
  overflow-y: scroll;
  overflow-x: hidden;
  // will-change: scroll-position, scroll;
  // scroll-behavior: smooth;
  // transition: scroll 1s ease;
  scrollbar-width: 0;
  // scroll-behavior: smooth;
  // scroll-margin: 50px 0 0 0;
  // scroll-snap-margin-top: 100px;

  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
  &::selection {
    background: $s75;
    opacity: 0.5;
  }

  section {
    padding: 20px;
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-col {
  // display: flex;
  // flex-direction: column;
}

.cover-photo {
  position: relative;
  max-width: 100%;
  min-width: 110px;
  object-fit: cover;
  margin-top: 0%;
  margin-bottom: 0;
  opacity: 50%;
}